import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Seo from '~components/Seo'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, desktop } from '~styles/global'
import DataViewer from '~utils/DataViewer'
import Section from '~components/Section'
import RichText from '~components/RichText'
import useSiteSettings from '~utils/useSiteSettings'
import SanityLink from '~components/SanityLink'
import MailchimpForm from '~components/MailchimpForm'
import Image from '~components/Image'

const Contact = ({ data }) => {

	const {
		socialLinks,
	} = useSiteSettings()

	const page = data?.sanityContactPage

	return (
		<>
			<Seo 
				title={page?.title}
				metaTitle={page?.seo?.metaTitle}
				description={page?.seo?.metaDescription}
				image={page?.seo?.socialImage}
			/>
			<Header>
				<Container>
					<h1 className='h2-serif'><RichText content={page.headline}/></h1>
				</Container>
				<Line />
			</Header>
			<Row>
				<Heading className='h2-serif' >
					{/* {page.primaryContactHeading} */}
					{/* Start project button */}
					<RoundedButton link={page.startAProjectButton?.link}>{page.startAProjectButton?.text}</RoundedButton>
				</Heading>
				<Links>
					<LinkCol>
						<UnderlineLink className='h2-serif' link={page.mediaPrButton?.link}>{page.mediaPrButton?.text}</UnderlineLink>
					</LinkCol>
					<LinkCol>
						<UnderlineLink className='h2-serif' link={page.CareersButton?.link}>{page.CareersButton?.text}</UnderlineLink>
					</LinkCol>
				</Links>
			</Row>
			<Section><Line /></Section>
			<SecondRow>
				<Heading className='h2-serif' >{page.socialHeading}</Heading>
				<Detail>
					{socialLinks.map(link => (
						<div key={link.key}><SocialLink key={link.key} link={link.link}>{link.text}</SocialLink></div>
					))}	
				</Detail>
				<SubscribeHeading className='h2-serif' >{page.subscribeHeading}</SubscribeHeading>
				<Detail>
					<SignUp successMessage={'Success!'}/>
				</Detail>
			</SecondRow>
			<Section>
				<ContactImage image={page.image}/>
			</Section>
			<DataViewer data={page} name="page" />
		</>
	)
}

const Header = styled(Section)`
	margin-top: 140px;
	${mobile}{
		margin-top: 87px;
	}
`
const Container = styled.div`
	grid-column: span 16;
	margin-bottom: var(--m);
	${mobile}{
		margin-bottom: var(--m-l);
	}
`
const Line = styled.div`
	border-bottom: 1px solid var(--black);
	grid-column: span 16;
`
const Row = styled(Section)`
	padding-top: var(--s);
	padding-bottom: var(--m);
	${mobile}{
		padding-top: var(--m-m);
		padding-bottom: var(--m-l);
	}
`
const SecondRow = styled(Row)`
	padding-bottom: var(--s);
`
const Heading = styled.div`
	grid-column: span 4;
	${desktop}{
		grid-column: span 8;
	}
	${mobile}{
		grid-column: span 16;
		margin-bottom: 30px;
	}
`
const SubscribeHeading = styled(Heading)`
	${mobile}{
		margin-bottom: 20px;
	}
`
const Detail = styled.div`
	grid-column: span 4;
	${desktop}{
		grid-column: span 7;
		:not(:last-of-type){
			margin-bottom: 40px;
		}
	}
	${mobile}{
		grid-column: span 16;
		:not(:last-of-type){
			margin-bottom: var(--m-s);
		}
	}
`
const Links = styled.div`
	grid-column: span 8;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	/* grid-row-gap: 10px; */
	grid-column-gap: 25px;
	align-items: center;
	${desktop}{
		grid-template-columns: repeat(1, 1fr);
		grid-column: span 7;
		:not(:last-of-type){
			margin-bottom: 40px;
		}
	}
	${mobile}{
		grid-column-gap: 20px;
		grid-column: span 16;
		margin-top: 20px;
		:not(:last-of-type){
			margin-bottom: var(--m-s);
		}
	}
`
const LinkCol = styled.div`
	
`
const SocialLink = styled(SanityLink)`
	position: relative;
	display: inline-block;
	margin-bottom: 4px;
	:after {
		content: '';
		background-color: var(--black);
		height: 2px;
		position: absolute;
		bottom: 1px;
		left: 0;
		right: 0;
		bottom: 0px;
	}
`
const UnderlineLink = styled(SanityLink)`
	position: relative;
	:after {
		content: '';
		background-color: var(--black);
		height: 2px;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0.06em;
	}
`
const RoundedButton = styled(SanityLink)`
	background-color: var(--black);
	border-radius: 100px;
	color: white;
	padding: 8px 20px 9px 20px;
	display: inline-block;
`
const SignUp = styled(MailchimpForm)`
`
const ContactImage = styled(Image)`
	margin-bottom: var(--l);
	grid-column: span 16;
	${mobile}{
		margin-bottom: var(--m-l);
	}
`

Contact.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
	query ContactPageQuery($id: String) {
		sanityContactPage(id: {eq: $id}) {
			title
			slug {
				current
			}
			headline: _rawHeadline(resolveReferences: {maxDepth: 5})
			primaryContactHeading
			phoneNumber
			emailAddress
			startAProjectButton{
				...button
			}
			mediaPrButton{
				...button
			}
			CareersButton{
				...button
			}
			socialHeading
			subscribeHeading
			image {
				...imageWithAlt
			}
			seo{
				...seo
			}
		}
	}
`


export default Contact