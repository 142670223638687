import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import sendToMailchimp from '../utils/sendToMailchimp'
import {mobile, tablet} from '~styles/global'
import Button from '~components/Button'

const NewsletterSignupForm = ({ className, successMessage, showName }) => {
	const [values, setValues] = useState({})
	const [errors, setErrors] = useState([])
	const [success, setSuccess] = useState(false)

	const validate = e => {
		e.preventDefault()
		const newErrors = []

		if(!values.email) newErrors.push({
			field: 'email',
			message: 'The email field is required'
		})

		if(values.email && (!values.email.includes('@') || !values.email.includes('.'))) newErrors.push({
			field: 'email',
			message: 'Please check your email address'
		})

		if(newErrors.length === 0){
			submit()
		}
		else{
			setErrors(newErrors)
		}
	}

	const submit = () => {
		sendToMailchimp(values.email, values.fname, values.lname, (err, data) => {
			if(err){
				console.log(err, data)
				setErrors([{
					field: null,
					message: 'Sorry we can\'t sign you up right now.'
				}])
			}
			else{
				setSuccess(true)
			}
		})
	}

	const errorFields = errors.map(error => error.field)

	return (
		<Wrap className={className}>
			<Success css={css`
        opacity: ${success ? '1' : '0'};
        pointer-events: ${success ? 'all' : 'none'};
      `}>
				{successMessage}
			</Success>
			<FormWrap css={css`
        opacity: ${success ? '0' : '1'};
        pointer-events: ${success ? 'none' : 'inherit'};
        `}>
				<Form onSubmit={e => validate(e)} noValidate>
					{showName &&
          <>
          	<Input
          		className='body'
          		type="text"
          		name="fname"
          		placeholder="First Name"
          		onChange={e => setValues({...values, fname: e.target.value})}
          	/>
          	<Input
          		className='body'
          		type="text"
          		name="lname"
          		placeholder="Last Name"
          		onChange={e => setValues({...values, lname: e.target.value})}
          	/>
          </>
					}
					<InputWrap>
						<Input
							className='body'
							type="email"
							name="email"
							placeholder="email address"
							css={css`
								border-color: ${errorFields.includes('email') ? 'var(--red)' : 'var(--black)'};
							`}
							onChange={e => setValues({...values, email: e.target.value})}
						/>
					</InputWrap>
					{errors?.length > 0 &&
            <Errors className='sm-sans'>
            	{errors.map(error => <p>{error.message}</p>)}
            </Errors>
					}
					<ButtonContainer>
						<SubmitButton type="submit">
							<Arrow 
							>→</Arrow>
						</SubmitButton>
					</ButtonContainer>
				</Form>
			</FormWrap>
		</Wrap>
	)
}

const Wrap = styled.div`
  position: relative;
`

const FormWrap = styled.div`
  position: relative;
  transition: opacity 0.3s;
	max-width: 480px;
  box-sizing: border-box;
`
const Form = styled.form`
	display: flex;
	height: 26px;
	margin-top: 7px;
	${tablet}{
		height: 20px;
	}
`
const InputWrap = styled.div`
	position: relative;
	:after {
		content: '';
    background-color: black;
		opacity: 1;
    height: 2px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -4px;
		${tablet}{
			bottom: -2px;
		}
	}
`
const Input = styled.input`
	background: transparent;
	border: none;
	width: 100%;
	box-sizing: border-box;
	font-size: inherit;
	padding: 0;
	::placeholder{
		color: var(--black);
		opacity: 0.6;
	}
`
const Errors = styled.div`
	color: var(--red);
	position: absolute;
  top: 42px;
	${tablet}{
		top: 32px;
	}
`

const Success = styled.h5`
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s 0.3s;
`
const ButtonContainer = styled.div`
	${tablet}{
		display: grid;
		align-items: center;
	}
`
const SubmitButton = styled.button`
	margin-top: 3px;
	align-self: center;
`
const Arrow = styled.div`
	margin-left: 8px;
	position: relative;
	left: ${props => props.hover ? '3px' : '0px'};
	transition: 0.25s ease;
	display: ${props => props.reverse ? 'none' : 'inline-block'};
`


NewsletterSignupForm.propTypes = {
	successMessage: PropTypes.string,
	showName: PropTypes.bool,
}

export default NewsletterSignupForm
